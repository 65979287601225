import { Box, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import Hero from "../components/Hero/Hero";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo/Seo";
import StartNow from "../components/StartNow/StartNow";
import TileMenu from "../components/TileMenu/TileMenu";
import EmailCampaignsIcon from "../images/vector/feature_icons/email_campaigns.svg";
import ReportsIcon from "../images/vector/feature_icons/reports.svg";
import TemplatesIcon from "../images/vector/feature_icons/templates.svg";

const IndexPage = ({ location }) => {
  const sections = [
    {
      mainSection: true,
      title: "Features",
    },
    {
      mainSection: false,
      id: "campaigns",
      title: "Email Campaigns",
      subtitle: "Create and Schedule",
      content: () => (
        <Stack spacing="3">
          <Text>Connecting with your target audience could not be easier.</Text>
          <Text>With Motar.me you can:</Text>
          <Box pl="2">
            <UnorderedList>
              <ListItem>
                Create and send personalised emails to your ideal prospects. You
                can include the recipient’s name, company name and other
                specific information that relates to your recipients.
              </ListItem>
              <ListItem>
                Send your email sequences manually or schedule emails to send
                out at a time and frequency that you specify.
              </ListItem>
              <ListItem>
                Directly integrate your Gmail and Outlook account with Motar.me
                so responses will go straight to your inbox.
              </ListItem>
              <ListItem>
                Add recipients one at a time or upload your contact list.
              </ListItem>
            </UnorderedList>
          </Box>
        </Stack>
      ),
      background: "gray.100",
      icon: EmailCampaignsIcon,
    },
    {
      mainSection: false,
      id: "reports",
      title: "Response Reports",
      subtitle: "Generate",
      content: () => (
        <Stack spacing="3">
          <Text>
            Learn which leads to focus on by capturing important insights with
            Motar.me.
          </Text>
          <Text>
            Download reports at any time or you can preset a date for an
            automatic report to be generated. This ensures that you can keep
            track of open rates, replies and bounces.
          </Text>
          <Text>
            Use this valuable information to follow up with your highest quality
            leads to get more calls and meetings booked.
          </Text>
        </Stack>
      ),
      background:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #CBD5E0;",
      icon: TemplatesIcon,
    },
    {
      mainSection: false,
      id: "templates",
      title: "Email Templates",
      subtitle: "Save for later",
      content: () => (
        <Stack spacing="3">
          <Text>
            With Motar.me you can save your preferred emails to ‘Email Templates’
            so you do not need to rewrite the same email.
          </Text>
          <Text>
            This means your email sequence can be quickly and easily accessed
            and reused for the next list of contacts’.
          </Text>
          <Text>
            Personalize and edit these templates to ensure they are the right
            message for your target list to help increase your response rates.
          </Text>
        </Stack>
      ),
      background: "white",
      icon: ReportsIcon,
    },
  ];
  return (
    <Layout>
      <Seo />
      <Hero />
      <TileMenu location={location} sections={sections} />
      <StartNow />
    </Layout>
  );
};

export default IndexPage;
