import React from "react";
import { Box, GridItem, Text, Flex, Icon, Heading } from "@chakra-ui/react";
import { IoArrowDownSharp } from "react-icons/io5";

const MenuButton = ({ section, setSelectedId }) => {
  if (section.mainSection) {
    return (
      <GridItem
        backgroundColor="cyan.400"
        p={{ base: "4", sm: "6", lg: "8" }}
        onClick={() => setSelectedId(section.id)}
        key="main"
      >
        <Heading
          as="h2"
          fontWeight="bold"
          fontSize={{ base: "28px", lg: "4xl" }}
          color="white"
        >
          {section.title}
        </Heading>
      </GridItem>
    );
  } else {
    return (
      <GridItem
        p={{ base: "4", sm: "6", lg: "8" }}
        background={section.background}
        color="gray.800"
        cursor="pointer"
        _hover={{
          color: "green.900",
          background:
            "linear-gradient(0deg, rgba(198, 246, 213, 0.2), rgba(198, 246, 213, 0.2)), #EDF2F7;",
        }}
        onClick={() => setSelectedId(section.id)}
        id={section.id}
        key={section.id}
      >
        <Flex justifyContent="space-between" flexDir="column" height="100%">
          <Box>
            <Text fontSize={{ base: "xs", sm: "sm", lg: "md" }}>
              {section.subtitle}
            </Text>
            <Heading
              as="h3"
              fontSize={{ base: "sm", sm: "md", lg: "xl" }}
              fontWeight="bold"
            >
              {section.title}
            </Heading>
          </Box>
          <Icon
            as={IoArrowDownSharp}
            w={10}
            h={10}
            transform={{
              base: "rotate(-45deg)",
              lg: "rotate(-135deg)",
            }}
          />
        </Flex>
      </GridItem>
    );
  }
};
export default MenuButton;
