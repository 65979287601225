import React, { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Grid, Text, VStack } from "@chakra-ui/react";
import MenuButton from "./MenuButton";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { tileMenuAnimation } from "../../utils/animation";

gsap.registerPlugin(ScrollTrigger);

const StyledWrapper = styled(Box)`
  ${({ visible }) =>
    visible
      ? css`
          /* transform: translateY(0); */
          opacity: 1;
          filter: blur(0);
          transition: all 0.3s ease-in;
        `
      : css`
          /* transform: translateY(200px); */
          opacity: 0;
          /* filter: blur(-10px); */
          transition: all 0.3s ease-out;
        `};
`;

const TileMenu = ({ location, sections }) => {
  const sectionRef = useRef(null);
  const timeline = useRef(null);
  useEffect(
    () => tileMenuAnimation(sectionRef.current, timeline.current, false),
    [],
  );
  const [selectedId, setSelectedId] = useState(
    location.state ? location.state.features : null,
  );

  useEffect(() => {
    if (location.state && location.state.features)
      setSelectedId(location.state.features);
  }, [location.state]);

  const currentSection = sections.find(x => x.id === selectedId) || sections[0];

  return (
    <Box
      ref={sectionRef}
      mb="160px"
      pt="8"
      overflow="hidden"
      width="100%"
      gridColumn="2/3"
    >
      <Box
        display="flex"
        alignItems="center"
        // px={{ base: "6", md: "10", lg: "20", "3xl": "40" }}
        margin="0px auto"
        width={{ base: "100%", sm: "60%", xl: "100%" }}
        position="relative"
      >
        <Box position="absolute" top="-200px" left="0" id="features" />
        <Grid
          templateColumns={{ base: "1fr", xl: "496px 1fr" }}
          templateRows={{ base: "1fr 360px", xl: "496px" }}
          width="100%"
          gap={6}
        >
          <Grid
            templateColumns="repeat(2, 1fr)"
            templateRows={{
              base: "repeat(2,1fr)",
              sm: "1fr 160px",
              lg: "repeat(2,1fr)",
            }}
            className="leftSection"
            width={{ base: "100%", sm: "100%", xl: "496px" }}
            height={{ base: "90vw", sm: "340px", xl: "496px" }}
          >
            {sections.map((section, index) => (
              <MenuButton
                key={index}
                section={section}
                setSelectedId={setSelectedId}
              />
            ))}
          </Grid>

          {sections.map((section, index) => {
            if (section.mainSection) {
              return (
                <StyledWrapper
                  key={index}
                  background="linear-gradient(161.1deg, #CDF7FF 12.75%, #7BDBEE 102.89%)"
                  borderRadius={{
                    base: "0px 0px 220px 0px",
                    lg: "0px 0px 300px 0px",
                  }}
                  overflow="hidden"
                  visible={section.title === currentSection.title}
                  gridRow={{ base: 2, xl: 1 }}
                  gridColumn={{ base: 1, xl: 2 }}
                  className={
                    section.title === currentSection.title ? "rightSection" : ""
                  }
                >
                  <Box transform="scale(2)">
                    <StaticImage
                      src="../../images/laptop.webp"
                      alt="An app view on laptop"
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </Box>
                </StyledWrapper>
              );
            } else {
              return (
                <StyledWrapper
                  key={index}
                  backgroundColor="gray.50"
                  borderRadius={{
                    base: "0px 0px 220px 0px",
                    lg: "0px 0px 300px 0px",
                  }}
                  overflow="hidden"
                  p={{ base: "6", sm: "10", xl: "14" }}
                  d="flex"
                  flexDir="column"
                  justifyContent="space-between"
                  visible={section.title === currentSection.title}
                  gridRow={{ base: 2, xl: 1 }}
                  gridColumn={{ base: 1, xl: 2 }}
                  className={
                    section.title === currentSection.title ? "rightSection" : ""
                  }
                >
                  <VStack
                    spacing={{ base: "0.5", xl: "0.5" }}
                    alignItems="left"
                  >
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "28px", xl: "4xl" }}
                      mb={{ base: "2", xl: "3" }}
                    >
                      {section.title}
                    </Text>
                    {/* {section.content.split("\n").map(c => (
                      <Text
                        fontSize={{ base: "xs", sm: "sm", lg: "md", xl: "lg" }}
                      >
                        {c}
                      </Text>
                    ))} */}
                    {section.content && <section.content />}
                  </VStack>
                  <Box>
                    <section.icon />
                  </Box>
                </StyledWrapper>
              );
            }
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default TileMenu;
