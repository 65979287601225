import { Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Autoplay]);

const ScreensSwiper = () => (
  <Box
    className="fadeInBottom"
    position="absolute"
    left={{ base: "0", md: "-200px", lg: "-50px", xl: "0", "3xl": "0" }}
    top={{ base: "80px", md: "180px", lg: "0" }}
    width={{ base: "100%", md: "550px", xl: "820px", "3xl": "1021px" }}
    zIndex="38"
  >
    <Box position="relative">
      <StaticImage
        preload="true"
        alt="Empty macbook"
        src="../../images/empty_macbook.webp"
      />
      <Box
        position="absolute"
        top="8%"
        left="15%"
        width={{ base: "70%", "3xl": "70%" }}
        height={{ base: "80%", "3xl": "80%" }}
        overflow="hidden"
        transform="translateZ(0)"
        zIndex="1"
        background="white"
      >
        <Swiper
          style={{
            display: "block",
            height: "100%",
          }}
          allowTouchMove={true}
          autoplay={{ delay: 3000 }}
          loop
        >
          <SwiperSlide>
            <StaticImage
              style={{
                height: "100%",
                width: "100%",
              }}
              preload="true"
              alt="App mockup"
              src="../../images/campaigns_mockup.webp"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              style={{
                height: "100%",
                width: "100%",
              }}
              preload="true"
              alt="App mockup"
              src="../../images/templates_mockup.webp"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              style={{
                height: "100%",
                width: "100%",
              }}
              preload="true"
              alt="App mockup"
              src="../../images/campaign_reports_mockup.webp"
            />
          </SwiperSlide>
        </Swiper>
      </Box>
    </Box>
  </Box>
);

export default ScreensSwiper;
