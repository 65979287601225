import React, { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Button, Stack, Text, VStack } from "@chakra-ui/react";
import { IoEnterOutline } from "react-icons/io5";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { rowWithImageAndTextAnimation } from "../../utils/animation";

gsap.registerPlugin(ScrollTrigger);

const StartNow = () => {
  const sectionRef = useRef(null);
  const timeline = useRef(null);
  useEffect(
    () =>
      rowWithImageAndTextAnimation(sectionRef.current, timeline.current, false),
    [],
  );

  return (
    <Box
      ref={sectionRef}
      overflow="hidden"
      width="100%"
      gridRow="3"
      gridColumn="1/-1"
      paddingBottom="200px"
    >
      <Box
        display="flex"
        alignItems="center"
        px={{ base: "6", md: "10", lg: "20", "3xl": "40" }}
        margin="0px auto"
        width={{ base: "100%", sm: "60%", lg: "100%" }}
        position="relative"
        flexDir="column"
      >
        <Box
          background="linear-gradient(265.54deg, #CBD5E0 -10.17%, #F9FCFF 60.01%);"
          borderRadius="400px 0px 0px 400px"
          width={{ base: "130vw", md: "80vw", lg: "100%" }}
          height={{ base: "285px", md: "528px", lg: "751px" }}
          position="absolute"
          left={{ base: "24px", md: "80px" }}
          top="0"
        />
        <Box
          position="absolute"
          left={{ base: "24px", md: "80px" }}
          top={{ base: "19px", md: "32px" }}
          height={{ base: "285px", md: "528px", lg: "751px" }}
          width="1206px"
          borderColor="gray.100"
          borderWidth="2px"
          borderRadius="400px"
          transform="matrix(1, 0, 0, -1, 0, 0)"
        />

        <Box
          width={{ base: "100%", md: "565px", xl: "917px" }}
          mx="auto"
          mt={{ base: "40px", md: "70px", lg: "90px" }}
        >
          <StaticImage
            src="../../images/monitor.webp"
            alt="An app view"
            className="imageFadeIn"
            placeholder="blurred"
          />
        </Box>

        <Box
          p={{ base: "6", lg: "8" }}
          backgroundColor="green.500"
          position={{ base: "unset", md: "absolute" }}
          bottom={{ md: "-120px", lg: "-50px" }}
          right={{ md: "-30%", lg: "100px", "3xl": "160px" }}
          zIndex="38"
          display="flex"
          justifyContent="space-between"
          flexDir="column"
          color="white"
          width={{ base: "100%", md: "496px" }}
          className="textFadeIn"
        >
          <VStack
            alignItems="left"
            paddingBottom={{ base: "46px", md: "6", lg: "60px" }}
            spacing="0"
          >
            <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>Start now</Text>
            <Text
              fontSize={{ base: "sm", md: "md", lg: "xl" }}
              fontWeight="bold"
            >
              Sign up to Motar.me
            </Text>
            <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
              After signing up you'll be able to send email campaigns via
              Outlook, Gmail or AWS
            </Text>
          </VStack>
          <Stack
            spacing={{ base: "4", md: "6" }}
            direction={{ base: "column", md: "row" }}
          >
            <Button
              as="a"
              href={process.env.GATSBY_APP_URL}
              variant="outline"
              w="full"
              colorScheme="white"
              _hover={{
                color: "green.50",
                backgroundColor: "green.900",
              }}
              leftIcon={<IoEnterOutline />}
            >
              Sign up
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default StartNow;
