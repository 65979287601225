import { Box, Heading, Text, VStack, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import BigOvals from "../../images/vector/big_ovals.svg";
import Oval from "../../images/vector/oval.svg";
import SmallOval from "../../images/vector/small_oval.svg";
import ScreensSwiper from "../ScreensSwiper/ScreeensSwiper";

const StyledBigOvals = styled(BigOvals)`
  position: absolute;

  left: 80px;
  top: -520px;
  z-index: 1;
  transform: scale(0.6);

  @media screen and (min-width: 48em) {
    left: -200px;
    top: -100px;
    transform: scale(1);
  }
  @media screen and (min-width: 62em) {
    left: -130px;
    top: -50px;
    transform: scale(1);
  }
`;

const StyledSmallOval = styled(SmallOval)`
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.8);
  z-index: 1;
  @media screen and (min-width: 48em) {
    right: -50px;
    top: 100px;
    transform: scale(1);
  }
  @media screen and (min-width: 62em) {
    right: -50px;
    top: -90px;
    transform: scale(1);
  }
`;

const StyledOval = styled(Oval)`
  display: none;
  @media screen and (min-width: 62em) {
    position: absolute;
    right: 0px;
    bottom: -120px;
    z-index: 1;
  }
`;

const Hero = () => {
  const heroRef = useRef(null);
  useEffect(() => {
    const ovals = heroRef.current.querySelectorAll(".oval");
    const fadeInElements = heroRef.current.querySelectorAll(".fadeInBottom");

    gsap
      .timeline({ defaults: { delay: 0.5 } })
      .set(heroRef.current, { opacity: 1 })
      .from(
        ovals,
        {
          x: 200,
          opacity: 0,
          duration: 0.6,
          stagger: 0.2,
        },
        0,
      )
      .from(
        fadeInElements,
        {
          opacity: 0,
          y: 50,
          duration: 0.6,
          stagger: 0.3,
        },
        0.3,
      );
  }, []);

  return (
    <Box
      pb="250px"
      pt={{ base: "120px", md: "200px", lg: "207px" }}
      overflow="hidden"
      width="100%"
      // maxWidth="1600px"
      // gridColumn="2/3"

      gridColumn="1/-1"
    >
      <Box
        ref={heroRef}
        opacity="1"
        display="flex"
        alignItems="center"
        flex="0 1 0%"
        maxWidth={{ base: "unset", "3xl": "1600px" }}
        px={{ base: "6", md: "10", lg: "20", "3xl": "0" }}
        margin="0px auto"
        position="relative"
        flexDir={{ base: "column", md: "row" }}
      >
        <VStack
          className="fadeInBottom"
          spacing="8"
          maxW={{ base: "100%", md: "453px", xl: "496px", "3xl": "678px" }}
          alignItems="left"
          zIndex="38"
        >
          <Heading
            as="h1"
            fontSize={{ base: "28px", md: "36px", xl: "48px", "3xl": "64px" }}
            mb={{ base: "0", md: "0", lg: "2", xl: "6" }}
          >
            Automate outbound emails with Motar.me
          </Heading>
          <Text fontSize={{ base: "sm", "3xl": "lg" }}>
            Create personalized messages, schedule emails to your target list in
            the sequence and with the frequency that you specify and get
            responses straight to your inbox.
          </Text>
          <Button
            as="a"
            href={process.env.GATSBY_APP_URL + "/register"}
            w="198px"
            variant="outline"
          >
            Start my Free Trial
          </Button>
        </VStack>
        <Box
          position="relative"
          width="100%"
          //   transformStyle="preserve-3d"
          // left="0"
          height={{ base: "260px", md: "260px", xl: "500px", "3xl": "611px" }}
        >
          <ScreensSwiper />
          <StyledBigOvals />
          <StyledSmallOval />
          <StyledOval />
        </Box>
        {/* <Oval /> */}
      </Box>
    </Box>
  );
};

export default Hero;
