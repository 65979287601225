import { gsap } from "gsap";

export const rowWithImageAndTextAnimation = (elem, timelineRef, reversed) => {
  const text = elem.querySelectorAll(".textFadeIn")[0].children;
  const image = elem.querySelectorAll(".imageFadeIn")[0];

  gsap.set(elem, { opacity: 1 });
  gsap.set(text, { autoAlpha: 1 });
  gsap.set(image, { opacity: 1 });

  timelineRef = gsap
    .timeline({
      scrollTrigger: {
        trigger: elem,
        start: "top center",
      },
    })
    .from(
      text,
      {
        duration: 0.5,
        autoAlpha: 0,
        y: 60,
        stagger: 0.2,
      },
      0,
    )
    .from(
      image,
      {
        autoAlpha: 0,
        x: reversed ? -60 : 60,
        duration: 0.8,
      },
      0,
    );

  return () => timelineRef.kill();
};

export const tileMenuAnimation = (elem, timelineRef, reversed) => {
  const leftSection = elem.querySelectorAll(".leftSection")[0];
  const rightSection = elem.querySelectorAll(".rightSection");

  gsap.set(elem, { opacity: 1 });
  gsap.set(leftSection, { autoAlpha: 1 });
  gsap.set(rightSection, { autoAlpha: 1 });

  timelineRef = gsap
    .timeline({
      scrollTrigger: {
        trigger: elem,
        start: "top center",
      },
    })
    .from(
      leftSection,
      {
        duration: 0.6,
        autoAlpha: 0,
        x: -600,
        stagger: 0.2,
      },
      0,
    )
    .from(
      rightSection,
      {
        autoAlpha: 0,
        x: 600,
        duration: 0.6,
      },
      0.3,
    );

  return () => timelineRef.kill();
};
